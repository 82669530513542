<template>
  <div coach-settlement-history>
    <div class="top-wrap">
      <button class="btn-prev" @click="prevBtnHandler">
        <img src="/img/icon/icon-arrow-down-dark.svg">
      </button>
      <NavTab v-model="_navTabType" :items="navTabItems" class="nav-tab" />
    </div>
    <div class="contents-wrap">
      <CoachInfoBanner>
        <a href="https://help.lvup.gg/hc/ko/sections/4410782857753-%EC%A0%95%EC%82%B0-%EA%B0%80%EC%9D%B4%EB%93%9C" target="_blank"> {{ $t('_.infoBanner') }}
          <svg-arrow />
        </a>
      </CoachInfoBanner>
      <div class="filter-wrap">
        <div class="filter-item">
          <div class="search-filter">
            <div class="search-input-holder">
              <ValidationObserver ref="validator">
                <ValidationProvider v-slot="{errors}" name="search" rules="lengthMin:2">
                  <CoachSearchInput v-model="searchInput" :hasError="!!errors.length" :placeHolder="$t('_.searchPlaceholder')" @search="searchBtnHandler" />
                  <ValidationErrors :errors="errors" />
                </ValidationProvider>
              </ValidationObserver>
            </div>
            <ColorButton class="search-btn" type="dark" @click="searchBtnHandler">{{ $t('coaching.MC_SEARCH') }}</ColorButton>
          </div>
        </div>
        <div class="filter-item">
          <CoachDropdown v-model="durationFilter" :list="dateFilterList" :subLabel="$t('_.dateFilterLabel')" class="order-date-filter" />
        </div>
        <div class="filter-item">
          <CoachDropdown v-model="statusFilter" :list="statusFilterList" :subLabel="$t('_.statusFilterLabel')" class="order-status-filter" />
        </div>
      </div>
      <table-control :total-count="totalCount" :selected-count="selectedRowKeys.length">
        <a @click="isNotAvailableDownloadAll ? '' : download('all')">
          <ButtonDownload class="download-btn" :disabled="isNotAvailableDownloadAll">
            <span v-if="matchedMediaDevice === 'M'">전체 목록</span>
            <span v-else>전체 목록 내려 받기</span>
          </ButtonDownload>
        </a> <a @click="isNotAvailableDownloadSelected ? '' : download('selected')">
        <ButtonDownload class="download-btn" :disabled="isNotAvailableDownloadSelected">
          <span v-if="matchedMediaDevice === 'M'">선택 목록</span>
          <span v-else>선택 목록 내려 받기</span>
        </ButtonDownload>
      </a>
      </table-control>
      <CoTable ref="table" :loading="false" checkbox :value="settlements" rowKey="settlementId" @select="onSelect" @sort="onSort" @select-all="onSelectAll" @deselect-all="deselectAll">
        <TableColumn key="a" :render="renderSettlementDatetimeCell" :sort="sortOptions.settlementDatetime || ''" :width="130" field="settlementDatetime" title="정산일" align="center" />
        <TableColumn key="b" :render="renderStatusCell" :width="80" field="status" title="입금 상태" />
        <TableColumn key="d" :width="140" field="depositAmountText" title="실 입금 금액" />
        <TableColumn key="e" :width="150" field="periodText" title="주문 확정 기간" />
        <TableColumn key="f" :width="150" field="bank" title="은행명" />
        <TableColumn key="g" :width="150" field="settlementNumber" title="정산 번호" />
        <TableColumn key="h" :render="renderDetailBtn" :width="120" field="detail" title="상세 정보" align="center" />
      </CoTable>
      <no-content v-if="!hasContent" />
      <Pagination v-if="!loading" :chunk-size="listSize" :info="settlementInfo" theme="coaching" @change="changeCursor" />
      <Selector :list="listSizes" :value="listSize" class="selector-listsize" theme="white-reverse" @change="onListSizeChange" />
    </div>
  </div>
</template>

<script>
import ColorButton from 'shared/components/common/ColorButton.vue';
import cursorFetcher from 'shared/mixins/cursorFetcher';
import { commaDecimal } from 'shared/utils/numberUtils';
import { getPastFromNow } from 'shared/utils/timeUtils';
import { downloadFile } from '@shared/utils/fileUtils';
import NavTab from '@/views/components/coaching/question/NavTab.vue';
import CoachInfoBanner from '@/views/components/coaching/CoachInfoBanner.vue';
import CoachDropdown from '@/views/components/coaching/CoachDropdown.vue';
import TableColumn from '@/views/components/coaching/sales/TableColumn.vue';
import ButtonTableDetail from '@/views/components/coaching/sales/ButtonTableDetail.vue';
import StatusBadgeSettlement from '@/views/components/coaching/sales/StatusBadgeSettlement.vue';
import Pagination from '@/views/components/common/Pagination.vue';
import Selector from '@/views/components/coaching/Selector.vue';
import NoContent from '@/views/components/coaching/sales/NoContent.vue';
import TableControl from '@/views/components/coaching/sales/TableControl.vue';
import ButtonDownload from '@/views/components/coaching/ButtonDownload.vue';
import CoachSearchInput from '@/views/components/coaching/CoachSearchInput.vue';
import SvgArrow from '@/views/graphics/svg-arrow.vue';
import CoTable from '@/views/components/coaching/sales/CoTable.vue';

export default {
  name: 'CoachSettlementHistory',
  lexicon: 'coaching.settlementHistory',
  components: { SvgArrow, CoachSearchInput, ButtonDownload, TableControl, NoContent, Pagination, TableColumn, NavTab, CoachInfoBanner, CoachDropdown, ColorButton, Selector, CoTable },
  mixins: [cursorFetcher({
    settlementInfo: async ({ services, page, size, durationUnit, durationAmount, sort, sortBy, status, search, store }) => {
      let q = [];
      const dateRange = durationUnit !== '' ? getPastFromNow({ [durationUnit]: Math.round(durationAmount) }) : '';
      if (dateRange !== '') q.push(`settlementDatetime gt ${dateRange}`);
      if (status !== '') q.push(`status eq ${status}`);
      if (search) q.push(`search like ${search}`);
      q = q.join(' and ');
      if (store?.commit) store.commit('info/setPreviousQuery', q);
      let addendum = {};
      if (sort !== '' && sortBy !== '') addendum = { order: [sortBy, sort.toUpperCase()].join(' ') };
      if (store) store.commit('ui/setLoading', true);
      const result = await services.coaching.getSettlements({ cursor: page, size, q, ...addendum });
      if (store) store.commit('ui/setLoading', false);
      return result;
    },
  })],
  data: () => ({
    navTabType: 'settlementHistory',
    searchInput: '',
    settlementInfo: /**  @type{PagedSettlements} */ {},
    hasCursorChanged: false,
    selectedRowKeys: [],
    previousQuery: '',
  }),
  watch: {
    settlementInfo() {
      /** cursor 변경 시, pagination에 scroll 맞추도록 하는 기능 */
      this.$refs.table.deselectedAll();
      if (this.hasCursorChanged) {
        setTimeout(() => {
          const el = this.$el;
          if (!el) return;
          this.$scroll.scrollTo(el.scrollHeight - window.innerHeight);
        }, 0);
        this.hasCursorChanged = false;
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state?.ui?.loading ?? false;
    },
    settlements() {
      return (this.settlementInfo?.items ?? []).map(settlement => {
        const s = /** @type{Settlement} */settlement;
        return {
          ...s,
          depositAmountText: this.$t('coaching.PC_store_cost2', { amount: commaDecimal(s.depositAmount) }),
          periodText: [this.$date(s.startDatetime, 'monthDateDash'), this.$date(s.endDatetime, 'monthDateDash')].join(' ~ '),
        };
      });
    },
    totalCount() {
      return this.settlementInfo?.totalCount ?? 0;
    },
    navTabItems() {
      return [{ value: 'salesHistory', text: this.$t('coaching.salesHistory.title') }, { value: 'settlementHistory', text: this.$t('_.title') }];
    },
    _navTabType: {
      get() {
        return this.navTabType;
      },
      set(v) {
        if (v === 'salesHistory') {
          this.$router.push({ name: 'CoachSalesHistory' });
        }
      },
    },
    durationFilter: {
      get() {
        return this.$route?.query?.duration ?? 'all';
      },
      set(v) {
        if (v === 'all') {
          this.$router.push({ query: this.getRemovedQuery(['duration']) });
          return;
        }
        this.$router.push({ query: this.getMergedQuery({ duration: v, page: 0 }) });
      },
    },
    statusFilter: {
      get() {
        return this.$route?.query?.status ?? 'all';
      },
      set(v) {
        if (v === 'all') {
          this.$router.push({ query: this.getRemovedQuery(['status']) });
          return;
        }
        this.$router.push({ query: this.getMergedQuery({ status: v, page: 0 }) });
      },
    },
    dateFilterList() {
      return [
        { value: 'all', label: this.$t('_.filterDateAll') },
        ...[{ days: 14 }, { months: 1 }, { months: 2 }, { months: 3 }, { months: 4 }, { months: 5 }].map(this.getDurationItem.bind(this)),
      ];
    },
    statusFilterList() {
      const list = ['COMPLETED', 'WAITING', 'CARRY_OVER', 'EXCLUDED'].map(value => ({ value, label: this.$t(`_.status.${value}`) }));
      return [{ value: 'all', label: this.$t('_.filterStatusAll') }, ...list];
    },
    page: {
      get() {
        return Math.round(this.$route.query?.page ?? 0);
      },
      async set(page) {
        this.hasCursorChanged = true;
        this.$router.push({ query: this.getMergedQuery({ page }) });
        this.settlementInfo.cursor = page;
      },
    },
    listSize: {
      get() {
        return this.$route?.query?.size ? Math.round(this.$route?.query?.size) : 10;
      },
      set(size) {
        this.$router.push({ query: this.getMergedQuery({ size, page: 0 }) });
      },
    },
    listSizes() {
      return [100, 50, 25, 10].map(value => ({ value, label: this.$t('_.listSize', [value]) }));
    },
    sortOptions: {
      get() {
        if (this.$route?.query?.sortBy && this.$route?.query?.sort) return { [this.$route.query.sortBy]: this.$route.query.sort };
        return {};
      },
      set(v) {
        if (Object.entries(v)?.length === 0) this.$router.push({ query: this.getRemovedQuery(['sortBy', 'sort']) });
        const [key, value] = Object.entries(v).filter(arg => !!arg[1])?.[0] ?? [];
        this.$router.push({ query: this.getMergedQuery({ sortBy: key, sort: value }) });
      },
    },
    sortBy() {
      return Object.keys(this.sortOptions)?.[0] ?? '';
    },
    sort() {
      return Object.values(this.sortOptions)?.[0] ?? '';
    },
    hasContent() {
      return this.settlementInfo?.totalCount !== 0;
    },
    isNotAvailableDownloadAll() {
      return !this.hasContent;
    },
    isNotAvailableDownloadSelected() {
      return this.selectedRowKeys.length < 1;
    },
  },
  methods: {
    prevBtnHandler() {
      this.$router.push({ name: 'TutorHome' });
    },
    searchBtnHandler() {
      if (this.searchInput.length === 1) return;
      const query = { ...this.$route.query, search: encodeURI(this.searchInput), cursor: 0 };
      this.$router.push({ query });
    },
    cancelBtnHandler() {
      this.searchInput = '';
    },
    renderDetailBtn({ row }, h) {
      const onClick = () => this.$router.push({ name: 'CoachSettlementDetail', params: { settlementId: row.settlementId } });
      return h(ButtonTableDetail, { on: { click: onClick } });
    },
    renderStatusCell({ row }, h) {
      return h(StatusBadgeSettlement, { props: { value: row.status } });
    },
    renderSettlementDatetimeCell({ row }, h) {
      return h('span', this.$date(row.settlementDatetime, 'dash'));
    },
    changeCursor(cursor) {
      this.page = cursor;
    },
    onListSizeChange(arg) {
      if (typeof arg === 'number') this.listSize = arg;
    },
    getDurationItem(duration) {
      const [unit, amount] = Object.entries(duration)?.[0] ?? [];
      const getDurationLabel = d => this.$t('_.filterRecent', [this.$duration(d)]);
      return { value: [unit, amount].join('-'), label: getDurationLabel(duration) };
    },
    onSelect({ selectedRowKeys }) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelectAll({ selectedRowKeys }) {
      this.selectedRowKeys = selectedRowKeys;
    },
    deselectAll() {
      this.selectedRowKeys = [];
    },
    onSort(params) {
      this.sortOptions = params;
    },
    async download(type) {
      let q = this.$store.state?.info?.previousQuery;
      let order = 'settlementDatetime desc';
      let result = {};

      if (this.$route.query.sortBy && this.$route.query.sort) {
        order = [this.$route.query.sortBy, this.$route.query.sort].join(' ');
      }

      if (this.$store) this.$store.commit('ui/setLoading', true);
      if (type === 'all') {
        result = await this.$services.coaching.downloadSettlements({ order, q });
      } else {
        q = `${q} and settlementId in ${this.selectedRowKeys.join(',')}`;
        result = await this.$services.coaching.downloadSettlements({ order, q });
      }
      if (this.$store) this.$store.commit('ui/setLoading', false);

      downloadFile(result);
    },
  },
  async asyncData({ mixinFetcher, services, route, store }) {
    const page = route.query.page ?? 0;
    const size = route.query.size ?? 10;
    const sort = route.query.sort ?? '';
    const sortBy = route.query.sortBy ?? '';
    const status = route.query.status ?? '';
    const search = route.query.search ?? '';
    const [durationUnit = '', durationAmount = ''] = (route.query?.duration ?? '').split('-');
    return { ...await mixinFetcher.fetch({ store, route, services, defaultParams: { page, size, sort, sortBy, durationUnit, durationAmount, status, search } }) };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-settlement-history] { .bgc(#fff);
  .top-wrap { .max-w(1260); .m(0, auto); .pt(40); .mb(32);
    .btn-prev { .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(#ebebf0); .br(12);
      &:hover {
        > img { .o(0.8);}
      }
      > img { .m(auto); .o(0.4);}
    }
    .nav-tab {.mt(46); border-bottom: 1px solid #d8d8d8; }
  }

  .contents-wrap {.max-w(1260); .m(0, auto);.pb(119);.rel;
    .filter-wrap { .flex; .mt(32); column-gap: 16px;
      .search-filter { .flex;
        .search-input-holder { .flex; .rel; .mr(8); .w(100%); .h(44);
          > fieldset {.w(100%); }
        }
        [validation-errors] {.c(#f00); .fs(12);}
        .search-btn { .h(44); .br(8); .w(66) }
      }
      .order-date-filter { .h(44);
        &[coach-dropdown] > div.open > ul {.max-h(unset); .h(fit-content); overflow: visible}
        > div { .h(inherit);
          > a {.h(44); }
        }
      }
      .order-status-filter { .h(44);
        &[coach-dropdown] > div.open > ul {.max-h(unset); .h(fit-content); overflow: visible}
        > div { .h(inherit);
          > a {.h(44); }
        }
      }
      .date-duration-filter { .w(100%);}

      .filter-item {
        &:nth-of-type(1) { flex-basis: 50%; }
        &:nth-of-type(2) { flex-basis: 25%; }
        &:nth-of-type(3) { flex-basis: 25%; }
        //&:nth-of-type(3) { flex-basis: 25%; }
      }
    }
    .download-btn {
      &:first-of-type {.mr(8);}
    }
  }
  [custom-table] {.mt(20);overflow-x: scroll;
    .ve-table-container {.min-w(680)}
    .cell-loading {.ml(3);.mr(3);
      &.settlementDatetime {.responsive-w(90px);.br(4);}
      &.status, &.detail {.wh(59, 30);.br(50)}
      &.depositAmountText, &.periodText, &.bank, &.settlementId, &.settlementNumber {.br(4);.max-w(120)}
    }
    thead > .ve-table-header-tr {
      th:nth-of-type(2) {.flex; .h(45); .pr(34); .items-center; .justify-center; flex-direction: row-reverse;}
      .ve-table-sort { .w(6);.mr(8)}
    }
  }
  [pagination] {.mt(24)}
  .selector-listsize {float: right;}
  @media (@ds-up) {
    .selector-listsize {clear: both;.abs;.r(0);.mt(-30);}
  }
  @media (@ds-down) and (@ml-up) {
    .contents-wrap, .top-wrap {.m(0, 20, 32, 20);}
  }
  @media (@ml-down) {
    .contents-wrap, .top-wrap {.m(0, 20, 20, 20);}
    .contents-wrap .filter-wrap {display: inline-block;.w(100%)}
    .filter-item { .block;.w(100%)}
    .filter-item + .filter-item {.mt(12)}
    .filter-item {
      &:nth-of-type(2) {
        .mt(16);
      }
    }
  }
}
</style>
